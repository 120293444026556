export type PartiallyNonNullable<T, TKey extends keyof T> = {
  [P in keyof T]: P extends TKey ? NonNullable<T[P]> : T[P]
}

type AssertSomePropsNotNull = <T, TKey extends keyof T>(
  obj: T,
  props: TKey[],
  callback?: (() => never) | ((nullProps: TKey[]) => never)
) => asserts obj is PartiallyNonNullable<T, TKey>

export const assertPropsNotNull: AssertSomePropsNotNull = <
  T,
  TKey extends keyof T,
>(
  obj: T,
  props: TKey[],
  callback = (nullProps: TKey[]) => {
    throw new Error(`Props ${nullProps.join(', ')} must not be null`)
  }
) => {
  const nullProps: TKey[] = []
  for (const key of props) {
    if (obj[key] == null) {
      nullProps.push(key)
    }
  }
  if (nullProps.length > 0) {
    callback(nullProps)
  }
}

export const setDefaultProps = <T, TKey extends keyof T>(
  obj: T,
  defaultProps: { [K in TKey]: T[K] }
): T => {
  for (const key of Object.keys(defaultProps) as TKey[]) {
    if (obj[key] === null) {
      obj[key] = defaultProps[key]
    }
  }
  return obj
}
