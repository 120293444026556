import {
  OpeningHoursSpecification,
  PostalAddress,
  ShoppingCenter,
  WebPage,
  WithContext,
} from 'schema-dts'
import { Centre } from '@scentregroup/shared/types/centre'
import { META_FALLBACK_IMAGE } from './../constants'

export const centreAddress = (
  centre: Pick<Centre, 'suburb' | 'state' | 'postcode' | 'physicalAddress'>
): PostalAddress => ({
  '@type': 'PostalAddress',
  addressLocality: centre.suburb,
  addressRegion: centre.state,
  postalCode: centre.postcode,
  streetAddress: centre.physicalAddress,
})

const tradingHoursToStructuredData = (
  hours: Centre['tradingHours']
): OpeningHoursSpecification[] =>
  hours.map(tradingHoursOfADay => ({
    '@type': 'OpeningHoursSpecification',
    opens: tradingHoursOfADay.openingTime,
    closes: tradingHoursOfADay.closingTime,
    validFrom: tradingHoursOfADay.date,
    validThrough: tradingHoursOfADay.date,
  }))

export const getCentreStructuredData = ({
  url,
  centre,
  imageUrl,
  name,
  description,
  tradingHours,
}: {
  url: string
  centre: Pick<
    Centre,
    | 'suburb'
    | 'state'
    | 'postcode'
    | 'physicalAddress'
    | 'facebook'
    | 'instagram'
  >
  imageUrl?: string | null
  name: string
  description: string
  tradingHours?: Centre['tradingHours']
}): WithContext<
  WebPage & { mainEntity: Exclude<ShoppingCenter, string> }
> | null => {
  if (!url) {
    return null
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': `${url}#webpage`,
    name,
    url,
    description,
    mainEntity: {
      openingHoursSpecification:
        tradingHours && tradingHoursToStructuredData(tradingHours),
      '@type': 'ShoppingCenter',
      name,
      image: imageUrl || META_FALLBACK_IMAGE,
      description,
      url,
      address: centreAddress(centre),
      sameAs: [centre.facebook, centre.instagram].filter(elem => elem),
    },
  }
}
