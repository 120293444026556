
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[centre]/[template]",
      function () {
        return require("private-next-pages/[centre]/[template]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[centre]/[template]"])
      });
    }
  