import { ApolloClient } from '@apollo/client'
import { ContactLink } from '@scentregroup/shared/types/centre'
import { CentreDetailsDocument } from '../../graphql'
import { assertPropsNotNull, setDefaultProps } from './utils'
import { CentreDetails } from './get-centre-details.query'

const requiredProps: (keyof CentreDetails)[] = [
  'title',
  'status',
  'slug',
  'physicalAddress',
  'suburb',
  'postcode',
  'state',
  'country',
  'latitude',
  'longitude',
  'timeZone',
  'telephoneNumber',
  'emailAddress',
  'facebook',
  'instagram',
  'navigationMenu',
  'centreFrontImage',
]

export async function getCentreDetails<TCacheShape>(
  client: ApolloClient<TCacheShape>,
  { slug, country }: { slug: string; country: string }
): Promise<CentreDetails | null> {
  const { data } = await client.query({
    query: CentreDetailsDocument,
    context: { version: 2 },
    variables: { slug, country },
  })

  const result = data?.cfCentreCollection?.items?.filter(
    item => item?.slug?.toLowerCase() === slug.toLowerCase()
  )?.[0]
  if (!result) {
    return null
  }

  const centre = setDefaultProps(result, { timeZone: 'UTC' })

  assertPropsNotNull(centre, requiredProps, () => {
    throw new Error('Centre object missing required properties')
  })

  const filteredFeatures = centre.features
    ? centre.features.filter((feature): feature is string => Boolean(feature))
    : null

  return { ...centre, features: filteredFeatures }
}

export const buildContactLinks = (centre: CentreDetails): ContactLink[] =>
  [
    {
      type: 'facebook',
      url: centre.facebook,
    },
    { type: 'instagram', url: centre.instagram },
  ].filter((link): link is ContactLink => link.url !== null)
