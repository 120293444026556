import React from 'react'
import { setCacheHeaders } from '@scentregroup/shared/helpers/set-cache-headers'
import { useGetHrefContext } from '@scentregroup/shared/context'
import { completelyCanonicalUrl } from '@scentregroup/shared/helpers/canonical-link'
import { getDefaultCentreMetadata } from '@scentregroup/shared/helpers/get-default-centre-metadata'
import { localeToCountry } from '@scentregroup/shared/helpers/local-to-country'
import Layout from '@scentregroup/shared/hub-components/layout'
import {
  getCentreDetails,
  CentreDetails,
  buildContactLinks,
} from '@scentregroup/shared/helpers/centre'
import { H2 } from '@hub/heading'
import { CoreContainer } from '@hub/core-container'
import Head from 'next/head'
import { useStoredCentre } from '@scentregroup/shared/hooks/use-stored-centre'
import { SGLinkedData } from '@scentregroup/shared/hub-components/json-ld'
import {
  GetServerSidePropsResult,
  GetServerSidePropsContext,
} from '../../../types/next'
import { makeApolloClient } from '../../../lib/apolloClient'
import { getCentrePageTemplate } from '../../../lib/page-templates/fetching'
import { SGPageTemplate } from '@scentregroup/shared/types/page-templates'
import { OpenGraph } from '../../../lib/open-graph'
import {
  getTopStripProps,
  ITopStripProps,
} from '@scentregroup/shared/hub-components/top-strip'
import { Template } from '@scentregroup/shared/components/page-templates/template'
import { shouldRedirectForMismatchedRoute } from '@scentregroup/shared/lib/redirects'
import {
  CentreStaticData,
  fetchCentreStaticData,
} from '@scentregroup/shared/helpers/fetch-header-static-data'

export async function getServerSideProps(
  ctx: GetServerSidePropsContext<{
    centre: string
    template: string
  }>
): Promise<GetServerSidePropsResult<ICentreTemplatePageProps>> {
  setCacheHeaders(ctx.res)
  const country = localeToCountry(ctx.locale)
  const centreSlug = ctx.params?.centre
  const templateSlug = ctx.params?.template
  if (!centreSlug || !templateSlug) {
    return {
      notFound: true,
    }
  }

  const apollo = makeApolloClient()
  const centre = await getCentreDetails(apollo, { slug: centreSlug, country })
  if (!centre) {
    return {
      notFound: true,
    }
  }

  const redirectForCentreSlug = shouldRedirectForMismatchedRoute(
    '/[centre]/[template]',
    ctx.params,
    {
      centre: centre.slug,
    }
  )
  if (redirectForCentreSlug) {
    return redirectForCentreSlug
  }

  const [template, headerStaticData, topStripProps] = await Promise.all([
    getCentrePageTemplate(apollo, centreSlug, templateSlug),
    fetchCentreStaticData(apollo, {
      country: localeToCountry(ctx.locale),
      centre,
    }),
    getTopStripProps(apollo, country, centreSlug),
  ])
  if (!template) {
    return {
      notFound: true,
    }
  }

  if (templateSlug === 'westfield-plus-news-and-offers') {
    return {
      redirect: {
        destination: `/${centreSlug}/membership-news-and-offers`,
        permanent: true,
      },
    }
  }

  return {
    props: {
      country,
      centre,
      topStripProps,
      template,
      headerStaticData,
    },
  }
}

export interface ICentreTemplatePageProps {
  country: ReturnType<typeof localeToCountry>
  headerStaticData: CentreStaticData
  centre: CentreDetails
  template: SGPageTemplate
  topStripProps: ITopStripProps | null
}

export default function CentreTemplatePage({
  country,
  centre,
  topStripProps,
  template,
  headerStaticData,
}: ICentreTemplatePageProps): JSX.Element {
  const canonical = completelyCanonicalUrl(useGetHrefContext()())
  const meta = getDefaultCentreMetadata({
    centre,
    canonical,
  })

  const contactLinks = buildContactLinks(centre)
  useStoredCentre(centre)

  return (
    <>
      <Head>
        <title>{template.title}</title>
        <meta
          name="description"
          key="description"
          content={template.shortDescription || meta.description}
        />
        <link rel="canonical" href={canonical} />
      </Head>
      <SGLinkedData item={meta.schema} />
      <OpenGraph data={meta.openGraphData} />

      <Layout
        country={country}
        centre={{ ...centre, contactLinks }}
        topStrip={topStripProps}
        header={{
          variant: 'centre',
          data: headerStaticData,
        }}
      >
        <CoreContainer sx={{ marginBottom: 'spacing-sm' }}>
          <H2 as={'h1'}>{template.title}</H2>
        </CoreContainer>
        <Template template={template} activateH1Title={false} />
      </Layout>
    </>
  )
}
