import { ShoppingCenter, WebPage, WithContext } from 'schema-dts'
import { META_FALLBACK_IMAGE } from '../constants'
import { Centre } from '@scentregroup/shared/types/centre'
import { fullCentreTitle } from './full-centre-title'
import { getCentreStructuredData } from './get-centre-structured-data'

export const getDefaultCentreMetadata = ({
  canonical,
  centre,
  description: shortDescription,
  title: contentTitle,
  imageUrl,
}: {
  canonical: string
  centre: Pick<
    Centre,
    | 'title'
    | 'suburb'
    | 'state'
    | 'postcode'
    | 'physicalAddress'
    | 'facebook'
    | 'instagram'
  >
  title?: string | null
  description?: string | null
  imageUrl?: string | null
}): {
  title: string
  description: string
  openGraphData: Record<string, any> | null
  schema: WithContext<
    WebPage & { mainEntity: Exclude<ShoppingCenter, string> }
  > | null
} => {
  const title = contentTitle || fullCentreTitle(centre)
  const description =
    shortDescription ||
    `Westfield ${centre.title} - your one-stop hub for shopping, fun & relaxation. Shop our range of fashion, entertainment, dining & wellbeing or check out great events & activities.`
  return {
    title,
    description,
    // TODO: In event a url is not available don't return incomplete structured data
    openGraphData: canonical
      ? {
          url: canonical,
          type: 'ShoppingCenter',
          title,
          description,
          image: imageUrl || META_FALLBACK_IMAGE,
        }
      : null,
    schema: getCentreStructuredData({
      url: canonical,
      centre,
      imageUrl,
      name: title,
      description,
    }),
  }
}
