import { pick } from 'lodash'
import React from 'react'
import { useSessionStorage } from './use-session-stoage'

interface ICentreLink {
  title: string
  slug: string
}

export function useStoredCentre(
  centre?: ICentreLink
): [ICentreLink | null | undefined, (centreLink: ICentreLink | null) => void] {
  const [data, setData] = useSessionStorage<ICentreLink>('sg_centre')
  const setStoredCentre = React.useCallback(
    (data: ICentreLink | null) => {
      if (data === null) {
        setData(null)
      } else {
        setData(pick(data, ['title', 'slug']))
      }
    },
    [setData]
  )
  React.useEffect(() => {
    if (centre !== undefined) {
      setStoredCentre(centre)
    }
  }, [centre, setStoredCentre])
  return [data, setStoredCentre]
}
