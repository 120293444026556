import React from 'react'
import Head from 'next/head'

type Props = {
  data: Record<string, string> | null | undefined
}

export const OpenGraph: React.FC<React.PropsWithChildren<Props>> = ({
  data,
}: Props) =>
  data ? (
    <Head>
      {Object.entries(data).map(([property, content]) => (
        <meta
          // Setting a key here is important so Next can de-dupe meta tags, allowing
          // us to set "defaults" higher up the tree that individual components can
          // override if they want
          key={`og:${property}`}
          property={`og:${property}`}
          content={content}
        />
      ))}
    </Head>
  ) : null
